import { FaMapMarkerAlt } from "react-icons/fa"

const FootAddress = (props: any) => {
    return (
        <>
        <div className="md:col-span-12 col-span-12">
            <div className="block grid-cols-12 w-[100%] lg:mb-4">
                <p className="lg:text-[13px] md:text-[13px] text-[13px] text-[#FFFFFF] font-medium mb-3">ExC Academy</p>
                <p className="relative flex lg:text-[13px] md:text-[13px] text-[13px] flex-wrap items-center xs:justify-center text-[#FFFFFF]">
                    <FaMapMarkerAlt className="text-xs absolute top-[3px] md:top-1 left-0"/>&nbsp; &nbsp; &nbsp;No. 56/3, 3rd Floor, Vakil Square, Bannerghatta Main Road, Bangalore, Karnataka- 560029, India.
                </p>
            </div>
        </div>
        </>
    )
}
export default FootAddress;