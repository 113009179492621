"use client"
import QuickLinks from './quickLinks';
import FootContact from './footContact';
import FootAddress from './footAddress';
import FootMap from './footMap';
import CopyRight from './copyRight';
import SocialMedia from './socialMedia';
import ContactUs from './contactUs';
import { usePathname } from 'next/navigation';
import ApplyNow from './apply-now';

const Footer = (props: any) => {
    const pathname = usePathname();

    return (
        <>
            {/* {(pathname == "/" || pathname == "/contact-us") ? '' : <ContactUs />} */}
            <div className="bg-[#0D0D0D] lg:pt-14 md:pt-14 pt-7">
                <div className="container">
                    {/* <ApplyNow/> */}
                {/* <hr className="border-[#727272] mb-5" /> */}
                    <div className="grid grid-cols-12 xl:gap-16 lg:gap-6 gap-4 w-[100%] lg:mb-3 md:mb-3 mb-0">
                        <div className="lg:col-span-4 md:col-span-6 col-span-12">
                            <p className="lg:text-[18px] md:text-[18px] sm:text-[18px] text-[18px] font-semibold text-[#FFFFFF] mb-4">Quick Links</p>
                            <QuickLinks />
                        </div>
                        <div className="lg:col-span-8 md:col-span-6 col-span-12">
                            <p className="lg:text-[18px] md:text-[18px] sm:text-[18px] text-[18px] font-semibold text-[#FFFFFF] mb-4">Get in Touch</p>
                            <div className='grid lg:grid-cols-3 md:grid-cols-1 grid-cols-1 xl:gap-16 lg:gap-6 md:gap-4 w-full mb-3'>
                                <div className="mb-2 lg:mb-0 md:mb-0">
                                    <FootAddress />
                                </div>
                                <div className="">
                                    <FootContact />
                                </div>
                                <div>
                                    <FootMap />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="border-[#727272] mb-5" />
                    <div className="grid grid-cols-12 lg:gap-16 w-[100%]">
                        <div className="md:col-span-6 col-span-12">
                            <CopyRight />
                        </div>
                        <div className="md:col-span-6 flex lg:justify-end md:justify-end justify-start col-span-12">
                            <SocialMedia />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Footer;