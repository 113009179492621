import Link from "next/link";
import { FaMapMarkerAlt, FaClock } from "react-icons/fa"


const FootMap = (props: any) => {
    return (
        <>
            <div className="md:col-span-12 col-span-12">
                <div className="block grid-cols-12 w-[100%] lg:mb-4 md:mb-4 mb-2">
                    <Link target="_blank" rel="noopener" href="https://maps.app.goo.gl/VL38LHhshRENa4Z78" className="relative flex lg:text-[13px] md:text-[13px] text-[13px] flex-wrap items-center xs:justify-center text-[#FFFFFF] hover:text-[#c99de4] mb-3">
                        <FaMapMarkerAlt className="text-xs absolute top-[4px] md:top-1 left-0" />&nbsp; &nbsp; &nbsp; Get Directions
                    </Link>
                    <p className="relative flex lg:text-[13px] md:text-[13px] text-[13px] flex-wrap items-center xs:justify-center text-[#FFFFFF]">
                        <FaClock className="text-xs absolute top-[4px] md:top-1 left-0" />&nbsp; &nbsp; &nbsp; Mon - Fri : 10 AM - 6 PM
                    </p>
                </div>
            </div>
        </>
    )
}
export default FootMap;