import Link from "next/link";
import { IoIosMail } from "react-icons/io";
import { IoCall } from "react-icons/io5";

const contactItems = [
    {
        menuItem: "support@exc.academy",
    },
    {
        menuItem: "+91 974 110 2084",
    }
]

const FootContact = (props: any) => {
    return (
        <>
            <div className="md:col-span-12 col-span-12 mb-2 lg:mb-0 md:mb-0">
                <p className="relative flex lg:text-[13px] md:text-[13px] text-[13px] flex-wrap items-center xs:justify-center text-[#FFFFFF] mb-3">
                    <Link href="mailto:support@exc.academy"> <IoIosMail className="text-[15px] absolute top-[3px] md:top-1 left-0" />&nbsp; &nbsp; &nbsp; support@exc.academy </Link>
                </p>
                <p className="relative flex lg:text-[13px] md:text-[13px] text-[13px] flex-wrap items-center xs:justify-center text-[#FFFFFF]">
                <Link href="tel:+919741102084"><IoCall className="text-xs absolute top-[3px] md:top-1 left-0" />&nbsp; &nbsp; &nbsp; +91 974 110 2084 </Link>
                </p>
            </div>
        </>
    )
}
export default FootContact;