import Link from "next/link";
import { FaFacebookF, FaInstagram, FaXTwitter, FaLinkedinIn, FaYoutube } from "react-icons/fa6";
const SocialMedia = (props: any) => {
    return (
        <>
        <div className="md:col-span-12 col-span-12">
            <div className="inline-block grid-cols-12 w-[100%] mb-4">
                <Link rel="noopener" href="https://www.facebook.com/exalogicacademy" className="inline-block" target="_blank" aria-label="Facebook">
                    <FaFacebookF className="text-[#FFFFFF] hover:border-[#AA52C7] hover:text-[#AA52C7] border-[2px] w-[25px] h-[25px] p-1 mr-2 rounded-full"/>
                </Link>
                <Link href="https://twitter.com/excacademy" className="inline-block" target="_blank" rel="noopener" aria-label="Twitter">
                    <FaXTwitter className="text-[#FFFFFF] hover:border-[#AA52C7] hover:text-[#AA52C7] border-[2px] w-[25px] h-[25px] p-1 mr-2 rounded-full"/>
                </Link>
                <Link href="https://www.linkedin.com/company/excacademy/" className="inline-block" target="_blank"  rel="noopener"aria-label="Linkdin">
                    <FaLinkedinIn className="text-[#FFFFFF] hover:border-[#AA52C7] hover:text-[#AA52C7] border-[2px] w-[25px] h-[25px] p-1 mr-2 rounded-full"/>
                </Link>
                <Link href="https://www.youtube.com/@EXCAcademy" className="inline-block" target="_blank"   rel="noopener"aria-label="Youtube">
                    <FaYoutube className="text-[#FFFFFF] hover:border-[#AA52C7] hover:text-[#AA52C7] border-[2px] w-[25px] h-[25px] p-1 mr-2 rounded-full"/>
                </Link>
                <Link href="https://www.instagram.com/excacademy_/" className="inline-block" target="_blank"  rel="noopener" aria-label="Instagram">
                    <FaInstagram className="text-[#FFFFFF] hover:border-[#AA52C7] hover:text-[#AA52C7] border-[2px] w-[25px] h-[25px] p-1 mr-2 rounded-full"/>
                </Link>
            </div>
        </div>
        </>
    )
}
export default SocialMedia;