"use client"
import { useEffect, useState } from 'react';
import { Button } from '../ui/button';

const ReserveFreeBtn = () => {

    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
      const toggleVisibility = () => {
        if (window.scrollY > 300) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };
      window.addEventListener('scroll', toggleVisibility);
      return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);
  
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };

    return (
        <Button type="button" onClick={scrollToTop} className="py-3 hover:bg-gray-800 bg-black text-white uppercase rounded-none poppins-medium">Reserve free seat</Button>
    )

}

export default  ReserveFreeBtn;