import Link from "next/link"
import { FaAngleRight } from "react-icons/fa6"

const quickLinkItems = [
    {
        menuItem: "About ExC Academy",
        url: "/about-us",
    },
    {
        menuItem: "Terms & Conditions",
        url: "/terms-conditions",
    },
    {
        menuItem: "Our Courses",
        url: "/our-courses",
    },
    {
        menuItem: "Privacy Policy",
        url: "/privacy-policy",
    },
    // {
    //     menuItem: "Blogs",
    //     url: "/blog",
    // },
    {
        menuItem: "Contact Us",
        url: "/contact-us",
    },
    {
        menuItem: "Sitemap",
        url: "/sitemap",
    },

]

const QuickLinks = (props: any) => {
    const isFooter = (props.isFooter != null ? props.isFooter : true);

    return (
        <>
            <div className="md:col-span-12 col-span-12">
                {quickLinkItems.map((quickLinkItem, index) => (
                    <div className="inline-block grid-cols-6 w-[50%] mb-4" key={index}>
                        <Link href={quickLinkItem.url}
                            className={"inline-flex lg:text-[13px] md:text-[13px] text-[12px] flex-wrap items-center leading-none xs:justify-center hover:text-[#c99de4] dark:text-gray-400 dark:hover:text-white " + (isFooter ? 'text-[#FFFFFF]' : 'text-[#000000]')}
                        >
                            <FaAngleRight className="text-xs" />&nbsp;{quickLinkItem.menuItem}
                        </Link>
                    </div>
                ))}
            </div>
        </>
    )
}
export default QuickLinks;